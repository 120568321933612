import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  html {
    -webkit-text-size-adjust: 100%;
    font-feature-settings: normal;
    scroll-behavior: smooth;
  }
  a {
    text-decoration: none;
  }
  @font-face {
    font-family: 'Bebas';
    src: url('/fonts/BebasNeue-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: 'Bebas';
    src: url('/fonts/BebasNeue-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
  }
  @font-face {
    font-family: 'Nova';
    src: url('/fonts/Novelist_free.otf') format('truetype');
    font-weight: 300;
    font-style: normal;
  }
`;

export default GlobalStyle;