import React from 'react';
import styled from 'styled-components'
import GlobalStyles from "./globalStyles";
const App = () => {
    const ScrollTo = (e,id) => {
        e.preventDefault();
        const targetElement = document.querySelector(id);
        if (targetElement) {
            window.scrollTo({
                top: targetElement.offsetTop,
                behavior: 'smooth'
            });
        }
    }

    return (
        <Wrapper>
            <GlobalStyles />
            <Container>
                <Header>
                    <Nav>
                        <Linka onClick={(e)=> ScrollTo(e,'#about')} href={'#about'}>
                           Обо мне
                        </Linka>
                        <Linka onClick={(e)=> ScrollTo(e,'#work')} href={'#work'}>
                            Мои услуги
                        </Linka>
                        <Linka href={'https://www.instagram.com/olesya_stylist/'} target={'_blank'}>
                            Instagram
                        </Linka>
                    </Nav>
                </Header>
            </Container>
            <Section1>
                <Container>
                    <Flex>
                        <NameText>
                            <h1>Олеся</h1>
                            <h1>Тарновская</h1>
                        </NameText>
                        <Img1 src="/images/1.png" alt="first image"/>
                        <Stylist>Стилист</Stylist>
                    </Flex>
                </Container>
            </Section1>
            <Section2 id={'about'}>
                <Container>
                    <Flex2>
                        <TextBlock>
                            <h3>Обо мне</h3>
                            <p>
                                Меня зовут Олеся Тарновская. <br/><br/>
                                Я стилист и моя отличительная профессиональная особенность –индивидуальный подход. Соотвествие образов характеру и статусу, носибельность. <br/><br/>
                                Моя задача — поделиться своими знаниями, и научить самостоятельно поддерживать результат.<br/><br/>
                                Имею диплом художника и отлично разбираюсь в колористике, цветотипах и пластической анатомии.<br/><br/>
                                Обучалась стилю и имиджу, а также профайлингу,
                                что позволяет легко понять запросы и потребности моих клиентов. <br/><br/>
                                Обожаю моду и изучаю её историю.<br/><br/>
                                Регулярно смотрю показы и campaign любимых модных домов. <br/><br/>
                                Слежу за тенденциями и работами стилистов и fashion-фотографов.
                            </p>
                        </TextBlock>
                        <Img2 src="/images/2.png" alt="second images"/>
                    </Flex2>
                </Container>
            </Section2>
            <Section3 id={'work'}>
                <Container>
                    <H2>
                        Мои услуги
                    </H2>
                    <Flex4>
                        <Img3 src="/images/3.png" alt="third images"/>
                        <TextBlock2>
                            <Title3>
                                Разбор гардероба
                            </Title3>
                            <Ul>
                                <Li>
                                    <img src="/images/li.svg" alt="dot"/>
                                    <p>Проанализирую гардероб, разберу по сезонности</p>
                                </Li>
                                <Li>
                                    <img src="/images/li.svg" alt="dot"/>
                                    <p>Cоберу комбинации и комплекты из имеющейся одежды</p>
                                </Li>
                                <Li>
                                    <img src="/images/li.svg" alt="dot"/>
                                    <p>Составлю шопинг-лист для того, чтобы гардероб стал рациональными и функциональным</p>
                                </Li>
                                <Li>
                                    <img src="/images/li.svg" alt="dot"/>
                                    <p>Дам рекомендации по брендам для покупки необходимых items</p>
                                </Li>

                            </Ul>
                            <Img4 src='/images/4.png'/>
                        </TextBlock2>
                    </Flex4>
                    <Flex4>
                        <TextBlock2>
                            <Title3>
                                Шопинг со стилистом
                            </Title3>
                            <Ul>
                                <Li>
                                    <img src="/images/li.svg" alt="dot"/>
                                    <p>Встреча-знакомство, на которой вместе проговорим желаемый гардероб с учётом образа жизни, социального статуса и бюджета</p>
                                </Li>
                                <Li>
                                    <img src="/images/li.svg" alt="dot"/>
                                    <p>Предшопинг, на котором я предварительно отберу необходимые комплекты</p>
                                </Li>
                                <Li>
                                    <img src="/images/li.svg" alt="dot"/>
                                    <p>Шопинг, где вместе за несколько часов составим капсульный гардероб. Примерим новые необычные образы и совершим обдуманные покупки</p>
                                </Li>
                            </Ul>
                        </TextBlock2>
                        <Img5 src="/images/5.png" alt="third images"/>
                    </Flex4>
                    <Flex5>
                        <Img6 src="/images/6.png" alt="third images"/>
                        <TextBlock2>
                            <Title3>
                                Консультация по стилю
                            </Title3>
                            <Ul>
                                <Li>
                                    <img src="/images/li.svg" alt="dot"/>
                                    <p>Разработаю концепции вашего стилевого направления</p>
                                </Li>
                                <Li>
                                    <img src="/images/li.svg" alt="dot"/>
                                    <p>Научу секретам гармоничного сочетания вещей, фактур, принтов и аксессуаров</p>
                                </Li>
                                <Li>
                                    <img src="/images/li.svg" alt="dot"/>
                                    <p>Отвечу на все ваши вопросы, касающиеся стиля, моды и трендов</p>
                                </Li>
                            </Ul>
                            <Img7 src='/images/7.png'/>
                        </TextBlock2>
                    </Flex5>
                </Container>
            </Section3>
            <Footer>
                <Container>
                    <FlexFooter>
                        <LinkaFooter href={'https://api.whatsapp.com/send?phone=79164004469'}>
                            +7 916 400 44 69
                        </LinkaFooter>
                        <SmallFlex>
                            <Social href="https://telegram.me/olesya_stylist" target={'_blank'}>
                                <img src="/images/tg.svg" alt="telegram"/>
                            </Social>
                            <Social href={'https://www.instagram.com/olesya_stylist/'} target={'_blank'}>
                                <img src="/images/inst.svg" alt="instagram"/>
                            </Social>
                        </SmallFlex>
                    </FlexFooter>
                </Container>
            </Footer>
        </Wrapper>
    );
};

export default App;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`
const Container = styled.div`
  position: relative;
  margin: 0 auto;
  max-width: 1180px;
  flex: 1 1;
  @media only screen and (max-width: 1180px) {
    padding: 0 26px;
  }
  @media only screen and (max-width: 768px) {
    padding:0 22px;
  }
  @media only screen and (max-width: 360px) {
    padding: 0 18px;
  }
`
const Header = styled.header`
  padding: 30px 0 40px;
  @media only screen and (max-width: 960px) {
    padding: 24px 0 28px;
  }
  @media only screen and (max-width: 768px) {
    padding: 16px 0 24px;
  }
  @media only screen and (max-width: 480px) {
    padding: 12px 0 24px;
  }
  @media only screen and (max-width: 360px) {
    padding: 12px 0 20px;
  }
`
const Nav = styled.nav`
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  max-width: 501px;
  @media only screen and (max-width: 960px) {
    max-width: 444px;
  }
  @media only screen and (max-width: 768px) {
    max-width: 100%;
  }
`
const Linka = styled.a`
  color: #282828;

  font-family: "Poiret One", sans-serif;
  font-weight: 300;
  font-size: 20px;
  @media only screen and (max-width: 768px) {
    font-size: 16px;
  }
  &:hover {
    color: #69001D;
  }
`
const Flex = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const NameText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: #282828;
  font-family: "Poiret One", sans-serif;
  font-weight: 400;
  position: absolute;
  left: 0;
  top: 60px;
 
  @media only screen and (max-width: 768px) {
    top: 48px;
  }
  @media only screen and (max-width: 768px) {
   top: 38px;
  }
  @media only screen and (max-width: 480px) {
    top: 38px;
  }
  @media only screen and (max-width: 360px) {
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    position: relative;
    top: 0;
  }
  h1 {
    font-size: 92px;
    line-height: 92px;
    @media only screen and (max-width: 960px) {
      font-size: 64px;
      line-height: 64px;
      gap: 4px;
    }
    @media only screen and (max-width: 768px) {
      font-size: 56px;
      line-height: 56px;
    }
    @media only screen and (max-width: 480px) {
      font-size: 36px;
      line-height: 36px;
    }
  }
`
const Img1 = styled.img`
  width: 500px;
  height: 660px;
  @media only screen and (max-width: 960px) {
    width: 444px;
    height: 584px;
  }
  @media only screen and (max-width: 768px) {
    width: 340px;
    height: 444px;
  }
  @media only screen and (max-width: 480px) {
    width: 208px;
    height: 272px;
  }
  @media only screen and (max-width: 360px) {
    width: 176px;
    height: 228px;
    margin-top: 16px;
  }
`
const Stylist = styled.h2`
  position: absolute;
  right: 0;
  background: #69001D;
  bottom: 40px;
  font-size: 40px;
  font-family: "Poiret One", sans-serif;
  color: #fff;
  font-weight: 400;
  padding: 42px 108px 50px;
  @media only screen and (max-width: 960px) {
    padding: 32px 65px 40px;
    bottom: 40px;
  }
  @media only screen and (max-width: 768px) {
    padding: 26px 55px 32px;
    bottom: 28px;
    font-size: 32px;
  }
  @media only screen and (max-width: 480px) {
    font-size: 24px;
    padding: 12px 28px 19px;
    bottom: 20px;
  }
  @media only screen and (max-width: 360px) {
    bottom: 8px;
  }
`
const Section1 = styled.section`
  padding-bottom: 140px;
  @media only screen and (max-width: 960px) {
    padding-bottom: 100px;
  }
  @media only screen and (max-width: 768px) {
    padding-bottom: 80px;
  }
  @media only screen and (max-width: 480px) {
    padding-bottom: 56px;
  }
  @media only screen and (max-width: 360px) {
    padding-bottom: 48px;
  }
`
const Section2 = styled.section`
  background: #F5F1F3;
  padding-bottom: 140px;
  padding-top: 140px;
  @media only screen and (max-width: 960px) {
    padding-bottom: 100px;
    padding-top: 100px;
  }
  @media only screen and (max-width: 768px) {
    padding-bottom: 80px;
    padding-top: 72px;
  }
  @media only screen and (max-width: 480px) {
    padding-bottom: 56px;
    padding-top: 44px;
  }
  @media only screen and (max-width: 360px) {
    padding-bottom: 48px;
    padding-top: 36px;
  }
`
const Flex2 = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 80px;
  @media only screen and (max-width: 960px) {
    gap: 48px;
  }
  @media only screen and (max-width: 768px) {
    gap: 28px;
  }
  @media only screen and (max-width: 480px) {
   flex-direction: column;
    gap: 32px;
  }
  @media only screen and (max-width: 360px) {
    flex-direction: column;
    gap: 28px;
  }
`
const Img2 = styled.img`
  width: 500px;
  height: 750px;
  @media only screen and (max-width: 960px) {
    width: 444px;
    height: 666px;
  }
  @media only screen and (max-width: 768px) {
    width: 300px;
    height: 448px;
  }
  @media only screen and (max-width: 480px) {
    width: 100%;
    height: 100%;
  }
  @media only screen and (max-width: 360px) {
    width: 100%;
    height: 100%;
  }
`
const TextBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 60px;
  color: #282828;
  font-family: "Poiret One", sans-serif;
  font-weight: 400;
  width: 100%;
  @media only screen and (max-width: 960px) {
    gap: 36px;
  }
  @media only screen and (max-width: 768px) {
    gap: 28px;
  }
  @media only screen and (max-width: 480px) {
    gap: 20px;
  }
  @media only screen and (max-width: 360px) {
    gap: 16px;
  }
  h3 {
    color: #69001D;
    font-size: 48px;
    @media only screen and (max-width: 960px) {
      font-size: 40px;
    }
    @media only screen and (max-width: 768px) {
      font-size: 32px;
    }
    @media only screen and (max-width: 480px) {
      font-size: 24px;
    }
    @media only screen and (max-width: 360px) {
      font-size: 24px;
    }
  }
  p {
    font-size: 20px;
    @media only screen and (max-width: 960px) {
      font-size: 20px;
    }
    @media only screen and (max-width: 768px) {
      font-size: 16px;
    }
    @media only screen and (max-width: 480px) {
      font-size: 16px;
    }
    @media only screen and (max-width: 360px) {
      font-size: 16px;
    }
  }
`
const Footer = styled.footer`
  background: #69001D;
  padding: 80px 0;
  @media only screen and (max-width: 960px) {
    padding: 56px 0;
  }
  @media only screen and (max-width: 768px) {
    padding: 40px 0;
  }
  @media only screen and (max-width: 480px) {
    padding: 32px 0;
  }
  @media only screen and (max-width: 360px) {
    padding: 28px 0;
  }
`
const Social = styled.a`
 img {
   height: 48px;
   width: 48px;
   @media only screen and (max-width: 960px) {
     height: 48px;
     width: 48px;
   }
   @media only screen and (max-width: 768px) {
     height: 40px;
     width: 40px;
   }
   @media only screen and (max-width: 480px) {
     height: 28px;
     width: 28px;
   }
   @media only screen and (max-width: 360px) {
     height: 28px;
     width: 28px;
   }
 }
`
const SmallFlex = styled.div`
  display: flex; 
  gap: 50px;
  @media only screen and (max-width: 960px) {
    gap: 48px;
  }
  @media only screen and (max-width: 768px) {
    gap: 40px;
  }
  @media only screen and (max-width: 480px) {
    gap: 24px;
  }
  @media only screen and (max-width: 360px) {
    gap: 24px;
  }
`
const FlexFooter = styled.div`
  display: flex;
  justify-content: space-between; 
  align-items: center;
`
const LinkaFooter = styled.a`
    color: #fff;
  font-family: "Poiret One", sans-serif;
  font-size: 36px;

  @media only screen and (max-width: 960px) {
    font-size: 32px;
  }
  @media only screen and (max-width: 768px) {
    font-size: 28px;
  }
  @media only screen and (max-width: 480px) {
    font-size: 20px;
  }
  @media only screen and (max-width: 360px) {
    font-size: 20px;
  }
`
const Section3 = styled.section`
  background: #FFF;
  padding-bottom: 140px;
  padding-top: 120px;
  @media only screen and (max-width: 960px) {
    padding-bottom: 64px;
    padding-top: 80px;
  }
  @media only screen and (max-width: 768px) {
    padding-bottom: 80px;
    padding-top: 64px;
  }
  @media only screen and (max-width: 480px) {
    padding-bottom: 56px;
    padding-top: 44px;
  }
  @media only screen and (max-width: 360px) {
    padding-bottom: 48px;
    padding-top: 36px;
  }
 `
const Flex4 = styled.div`
  display: flex;
  flex-direction: row;
  gap: 70px;
  margin-bottom: 80px;
  @media only screen and (max-width: 960px) {
    gap: 20px;
    margin-bottom: 56px;
  }
  @media only screen and (max-width: 768px) {
    gap: 20px;
    margin-bottom: 40px;
  }
  @media only screen and (max-width: 480px) {
    flex-direction: column;
    gap: 16px;
    margin-bottom: 24px;
  }
  @media only screen and (max-width: 360px) {
    flex-direction: column;
    gap: 16px;
    margin-bottom: 24px;
  }
`
const H2 = styled.h2`
  color: #69001D;
  font-size: 48px;
  font-weight: 400;
  font-family: "Poiret One", sans-serif;
  padding-bottom: 70px;
  @media only screen and (max-width: 960px) {
    font-size: 40px;
    padding-bottom: 48px;
  }
  @media only screen and (max-width: 768px) {
    font-size: 32px;
    padding-bottom: 36px;
  }
  @media only screen and (max-width: 480px) {
    font-size: 24px;
    padding-bottom: 28px;
  }
  @media only screen and (max-width: 360px) {
    font-size: 24px;
    padding-bottom: 24px;
  }
`
const Img3 = styled.img`
  width: 500px;
  height: 680px;
  @media only screen and (max-width: 960px) {
    width: 444px;
    height: 604px;
  }
  @media only screen and (max-width: 768px) {
    width: 352px;
    height: 478px;
  }
  @media only screen and (max-width: 480px) {
    width: 100%;
    height: 100%;
  }
  @media only screen and (max-width: 360px) {
    width: 100%;
    height: 100%;
  }
`
const TextBlock2 = styled.div`
  display: flex;
  flex-direction: column;
  color: #282828;
  font-family: "Poiret One", sans-serif;
  font-weight: 400;
  width: 100%;

  p {
    font-size: 20px;
    @media only screen and (max-width: 960px) {
      font-size: 20px;
    }
    @media only screen and (max-width: 768px) {
      font-size: 16px;
    }
    @media only screen and (max-width: 480px) {
      font-size: 16px;
    }
    @media only screen and (max-width: 360px) {
      font-size: 16px;
    }
  }
`
const Title3 = styled.h3`
  color: #69001D;
  font-size: 36px;
  padding-bottom: 32px;
  @media only screen and (max-width: 960px) {
    font-size: 32px;
    padding-bottom: 32px;
  }
  @media only screen and (max-width: 768px) {
    font-size: 28px;
    padding-bottom: 24px;
  }
  @media only screen and (max-width: 480px) {
    font-size: 24px;
    padding-bottom: 24px;
  }
  @media only screen and (max-width: 360px) {
    font-size: 24px;
    padding-bottom: 20px;
  }`
const Img4 = styled.img`
  display: flex;
  width: 430px;
  height: 345px;
  align-self: end;
  @media only screen and (max-width: 960px) {
    width: 296px;
    height: 236px;
  }
  @media only screen and (max-width: 768px) {
    width: 210px;
    height: 168px;
  }
  @media only screen and (max-width: 480px) {
    width: 100%;
    height: 100%;
  }
  @media only screen and (max-width: 360px) {
    width: 100%;
    height: 100%;
  }
`
const Img5 = styled.img`
  width: 600px;
  height: 460px;
  @media only screen and (max-width: 960px) {
    width: 444px;
    height: 340px;
  }
  @media only screen and (max-width: 768px) {
    width: 348px;
    height: 266px;
  }
  @media only screen and (max-width: 480px) {
    width: 100%;
    height: 100%;
  }
  @media only screen and (max-width: 360px) {
    width: 100%;
    height: 100%;
  }
`
const Img6 = styled.img`
  width: 630px;
  height: 510px;
  @media only screen and (max-width: 960px) {
    width: 444px;
    height: 360px;
  }
  @media only screen and (max-width: 768px) {
    width: 352px;
    height: 284px;
  }
  @media only screen and (max-width: 480px) {
    width: 100%;
    height: 100%;
  }
  @media only screen and (max-width: 360px) {
    width: 100%;
    height: 100%;
  }
`
const Img7 = styled.img`
  display: flex;
  align-self: end;
  width: 240px;
  height: 200px;
  @media only screen and (max-width: 960px) {
    width: 136px;
    height: 112px;
  }
  @media only screen and (max-width: 768px) {
    width: 352px;
    height: 284px;
  }
  @media only screen and (max-width: 480px) {
   display: none;
  }
  @media only screen and (max-width: 360px) {
    display: none;
  }
`
const Flex5 = styled.div`
  display: flex;
  flex-direction: row;
  gap: 70px;
  margin-bottom: 80px;
  @media only screen and (max-width: 960px) {
    gap: 20px;
    margin-bottom: 56px;
  }
  @media only screen and (max-width: 768px) {
    gap: 20px;
    margin-bottom: 40px;
  }
  @media only screen and (max-width: 480px) {
    flex-direction: column-reverse;
    gap: 16px;
    margin-bottom: 24px;
  }
  @media only screen and (max-width: 360px) {
    flex-direction: column-reverse;
    gap: 16px;
    margin-bottom: 24px;
  }
`
const Ul = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
  margin-bottom: 30px;
  @media only screen and (max-width: 960px) {
    gap: 16px;
    margin-bottom: 16px;
  }
  @media only screen and (max-width: 768px) {
    gap: 12px;
    margin-bottom: 27px;
  }
  @media only screen and (max-width: 480px) {
    gap: 8px;
    margin-bottom: 16px;
  }
  @media only screen and (max-width: 360px) {
    gap: 8px;
    margin-bottom: 16px;
  }
`
const Li = styled.div`
    display: flex;
    flex-direction: row;
    gap: 16px;
    align-items: flex-start;
  @media only screen and (max-width: 960px) {
    gap: 12px;
  }
  @media only screen and (max-width: 768px) {
    gap: 12px;
  }
  @media only screen and (max-width: 480px) {
    gap: 12px;
  }
  @media only screen and (max-width: 360px) {
    gap: 12px;
  }
  img {
    width: 24px;
    height: 24px;
  }
  p {
    font-size: 20px;
    color: #282828;
    font-family: "Poiret One", sans-serif;
    font-weight: 400;
    @media only screen and (max-width: 960px) {
      font-size: 20px;
    }
    @media only screen and (max-width: 768px) {
      font-size: 16px;
    }
    @media only screen and (max-width: 480px) {
      font-size: 20px;
    }
    @media only screen and (max-width: 360px) {
      font-size: 20px;
    }
  }
`